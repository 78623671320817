import { useSelector } from 'react-redux';
import { useFirebaseReadOnce } from './firebase';
import { ReduxState } from 'reducers';
import { User } from 'shared';
import { useCallback, useMemo } from 'react';
import { applyCode, cancelSubscription as _cancelSubscription } from 'api/payment';
import Analytics from 'analytics';
import { Notification } from 'components/NotificationsDock';
import moment from 'moment';

const defaultPlans = {
  prem: { price: 6, period: 'mo', discountPrice: null, discountStr: null },
  annprem: { price: 48, period: 'year', discountPrice: null, discountStr: null },
  premNoTrial: { price: 6, period: 'mo', discountPrice: null, discountStr: null },
  annpremNoTrial: { price: 48, period: 'year', discountPrice: null, discountStr: null },
};

export const planPrice = (planId, storedPrice = null, plans = defaultPlans, annualCostPerMonth = false) => {
  let plan = plans?.[planId] || defaultPlans.prem;
  let price = storedPrice || plan.discountPrice || plan.price;

  let discountSuffix = '';
  if (!storedPrice && plan.discountPrice && plan.discountStr) {
    discountSuffix = ' ' + plan.discountStr;
  }
  let period = plan.period;

  // annualCostPerMonth is used to return $4/mo instead of $48/year.
  if (annualCostPerMonth && period === 'year') {
    price = price / 12;
    period = 'mo';
  }
  let priceStr = price.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
  return `${priceStr}/${period}${period === 'mo' ? '.' : ''}` + discountSuffix;
};

export const planTitle = (id) => {
  switch (id) {
    case 'free':
      return 'Free';

    case 'prem':
    case 'premNoTrial':
    case '17268': // sandbox
    case '738217': // paddle
    case '73138': // sandbox no trial
    case '775026': // paddle no trial
      return 'Pro';

    case 'annprem':
    case 'annpremNoTrial':
    case '17269': // sandbox
    case '738218': // paddle
    case '73139': // sandbox no trial
    case '775027': // paddle no trial
      return 'Pro Annual';

    case 'patreon':
      return 'Patreon';

    default:
      return 'Early bird';
  }
};

export const usePremium = () => {
  const user = useSelector<ReduxState, User>((state) => state.account.user);
  const { data: plansData, loading } = useFirebaseReadOnce('config-public/plans');
  const plansInfo = plansData || defaultPlans;
  const plans = useMemo(
    () => ({
      prem: 'Go Pro for ' + planPrice('prem', null, plansInfo) + ' billed monthly',
      annprem: 'Go Pro for ' + planPrice('annprem', null, plansInfo, true) + ' billed annually',
      premNoTrial: 'Go Pro for ' + planPrice('premNoTrial', null, plansInfo) + ' billed monthly',
      annpremNoTrial: 'Go Pro for ' + planPrice('annpremNoTrial', null, plansInfo, true) + ' billed annually',
    }),
    [plansInfo]
  );
  const {
    activeSubscription,
    activeSubscriptionId,
    isPremium,
    premiumUntil,
    premiumPlan,
    premiumPrice,
    premiumTraits,
    // isFreeTrial,
    subscriptionPendingProcessing,
    premiumContext,
  } = user;
  const isExpired = moment().isAfter(premiumUntil, 'day');

  const cancelSubscription = useCallback(async () => {
    try {
      if (!activeSubscriptionId) throw new Error('No active subscription');

      await _cancelSubscription(activeSubscriptionId);
      Analytics.event({
        category: 'Premium',
        action: premiumPlan + ' subscription Cancelled',
      });
      Notification.show('Subscription cancelled');
    } catch (err) {
      console.log('Error cancelling subscription', err);
      Analytics.event({
        category: 'Premium',
        action: 'Error cancelling subscription',
      });
      Notification.showError('Error cancelling subscription. Please contact us');
    }
  }, []);

  const datePretty = moment(premiumUntil).format('LL');
  const pricePretty = activeSubscription
    ? planPrice(premiumPlan, activeSubscription?.paddle?.next_payment_amount ?? premiumPrice)
    : '';
  let premiumStatusVerbose = '';
  let premiumStatusText = '';
  let premiumStatusButton = '';
  if (premiumTraits.hasActiveSubscription && premiumTraits.isTrial) {
    premiumStatusVerbose = `Trial is active and first payment will be on ${datePretty} for ${pricePretty}`;
    premiumStatusText = 'Active Plan: ' + planTitle(premiumPlan);
    premiumStatusButton = `Manage Subscription`;
  } else if (!premiumTraits.hasActiveSubscription && (premiumTraits.isEarlyBird || premiumTraits.isPatreon)) {
    premiumStatusVerbose = `Thank you for your support! All Pro features are available to you.`;
    premiumStatusText = 'Active Plan:  ' + planTitle(premiumPlan);
    premiumStatusButton = 'Manage Subscription';
  } else if (premiumTraits.isSubscriptionCancelled && isPremium) {
    premiumStatusVerbose = `Plan is cancelled and will expire on ${datePretty}. You can use all plan benefits until then and reactivate after it expires.`; // TODO
    premiumStatusText = 'Cancelled Plan: ' + planTitle(premiumPlan); // TODO
    premiumStatusButton = `Pro Plan Active`; // Button is Disabled for now
  } else if (premiumTraits.hasActiveSubscription && activeSubscription.status === 'PastDue') {
    premiumStatusVerbose = `Your subscription is past due. Please update your payment method`;
    premiumStatusText = 'Payment Past Due'; // TODO
    premiumStatusButton = `Update Payment Method`;
  } else if (premiumTraits.hasActiveSubscription) {
    premiumStatusVerbose = `Plan is active and will auto-renew on ${datePretty} for ${pricePretty}`;
    premiumStatusText = 'Active Plan: ' + planTitle(premiumPlan);
    premiumStatusButton = `Manage Subscription`;
  } else if (!premiumTraits.hasActiveSubscription && premiumTraits.isTrialNoCard) {
    premiumStatusVerbose = `Trial is active until ${datePretty}`;
    premiumStatusText = 'Active Plan: Free Trial';
    premiumStatusButton = 'Claim Discount';
  } else {
    premiumStatusVerbose = `You are on the free tier. Unlock your planning potential with Trevor AI Pro.`;
    premiumStatusText = 'Upgrade to Pro'; // TODO
    premiumStatusButton = 'Upgrade to Pro';
  }

  const isTrialNoCardOver = premiumContext?.trialStart && isExpired;

  return {
    user,
    // they're in 'user' but enumerated here for conveniece
    activeSubscription,
    activeSubscriptionId,
    isPremium,
    premiumUntil,
    premiumPlan,
    premiumPrice,
    premiumTraits,
    subscriptionPendingProcessing,
    // isFreeTrial,
    //
    premiumStatusText,
    premiumStatusVerbose,
    premiumStatusButton,
    isExpired,
    isTrialNoCardOver,
    //
    plans,
    plansInfo,
    loading,
    // functions
    cancelSubscription,
    applyCode,
  };
};
