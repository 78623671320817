import { FC, useState } from 'react';
import { BorderlessSelect } from 'components/BorderlessSelect';
import { Link } from 'react-router-dom';
import { Divider, MenuItem, ListItemIcon, ListItemText, Tooltip, IconButton, Menu, Typography } from '@mui/material';
import { Clear, ViewDay, Settings } from '@mui/icons-material';
import { useUserPrefs } from 'common/useUserPrefs';
import { UserScheduleConfigItem } from 'shared';

const defaultColor = 'gray';
const explainer =
  'Scheduling suggestions for tasks within this list will respect the selected scheduling block or fall back to your default scheduling hours.';

interface UserScheduleZonesDropdownProps {
  selectedItemId: string | undefined | null;
  onSelectItem: (id: string) => void;
  placeholder?: string;
  rightAction?: JSX.Element;
  tooltip?: string;
}

interface UserScheduleZonesIconProps {
  selectedItemId: string | undefined | null;
  onSelectItem: (id: string) => void;
  placeholder?: string;
  rightAction?: JSX.Element;
  tooltip?: string;
  listColor?: string;
}

export const UserScheduleZonesDropdown: FC<UserScheduleZonesDropdownProps> = ({
  selectedItemId,
  onSelectItem,
  placeholder,
  rightAction,
  tooltip,
}) => {
  const userSchedule = useUserPrefs().user_schedule;

  const handleSelect = (e) => {
    onSelectItem(e.target.value || '');
  };

  const selectedItemIdx = userSchedule.moreItems?.findIndex((l) => l?.id === selectedItemId);
  const selectedItem = userSchedule.moreItems?.[selectedItemIdx];

  if (!userSchedule.moreItems) return null;

  return (
    <BorderlessSelect
      variant="outlined"
      displayEmpty
      MenuProps={{
        PaperProps: {
          sx: {
            width: '300px',
          },
        },
      }}
      sx={{
        height: '48px',
        width: '100%',
        flexGrow: 1,
        flexBasis: 0,
        minWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        '&:hover': { textDecoration: 'underline' },
      }}
      value={selectedItemId || ''}
      renderValue={(val) => (
        <Tooltip title={tooltip}>
          <div
            className="truncate"
            style={{ color: defaultColor, display: 'flex', alignItems: 'center', gap: '10px', fontSize: '0.9em' }}
          >
            {selectedItem ? itemName(selectedItem, selectedItemIdx) : placeholder}
          </div>
        </Tooltip>
      )}
      onChange={handleSelect}
      className="add-new-task-panel-lists"
    >
      <Typography variant="body2" color="textPrimary" sx={{ padding: '5px 10px' }}>
        {tooltip}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ padding: '5px 10px' }}>
        {explainer}
      </Typography>
      <Divider style={{ margin: '8px 0' }} />
      {userSchedule?.moreItems.map((item, idx) => (
        <MenuItem key={item.id} value={item.id} style={{ color: 'rgb(50,50,50)' }}>
          <ListItemText>{itemName(item, idx)}</ListItemText>
          {rightAction}
        </MenuItem>
      ))}
      {!!selectedItemId && <Divider />}
      {!!selectedItemId && (
        <MenuItem style={{ color: defaultColor }}>
          <ListItemIcon>
            <Clear />
          </ListItemIcon>
          <ListItemText>Clear</ListItemText>
        </MenuItem>
      )}
      <Divider style={{ margin: '8px 0' }} />
      <MenuItem component={Link} to="/app/settings#scheduling-blocks">
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <span style={{ color: 'rgb(50,50,50)' }}>Manage Scheduling Blocks</span>
      </MenuItem>
    </BorderlessSelect>
  );
};

export const UserScheduleZonesButton: FC<UserScheduleZonesIconProps> = ({
  selectedItemId,
  onSelectItem,
  placeholder,
  rightAction,
  tooltip = 'Link List to Scheduling Block',
  listColor = 'rgb(50,50,50)',
}) => {
  const userSchedule = useUserPrefs().user_schedule;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (id: string) => {
    onSelectItem(id);
    handleClose();
  };

  const selectedItemIdx = userSchedule.moreItems?.findIndex((l) => l?.id === selectedItemId);
  const selectedItem = userSchedule.moreItems?.[selectedItemIdx];

  if (!userSchedule.moreItems) return null;

  return (
    <>
      <Tooltip title={tooltip || ''}>
        <IconButton onClick={handleClick}>
          <ViewDay sx={{ color: selectedItemId ? listColor : 'gray' }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ sx: { width: '300px', maxWidth: '100vw' } }}
      >
        <Typography variant="body2" color="textPrimary" sx={{ padding: '5px 10px' }}>
          {tooltip}
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ padding: '5px 10px' }}>
          {explainer}
        </Typography>
        <Divider style={{ margin: '8px 0' }} />
        {userSchedule?.moreItems.map((item, idx) => (
          <MenuItem
            key={item.id}
            onClick={() => handleSelect(item.id)}
            sx={{
              backgroundColor: selectedItemId === item.id ? 'primary.highlight' : 'none',
              color: 'rgb(50,50,50)',
            }}
          >
            <ListItemText>{itemName(item, idx)}</ListItemText>
            {rightAction}
          </MenuItem>
        ))}
        {!!selectedItemId && [
          <Divider key="divider" />,
          <MenuItem key="clear" onClick={() => handleSelect('')} style={{ color: defaultColor }}>
            <ListItemIcon>
              <Clear />
            </ListItemIcon>
            <ListItemText>Clear</ListItemText>
          </MenuItem>,
        ]}
        <Divider style={{ margin: '8px 0' }} />
        <MenuItem component={Link} to="/app/settings#scheduling-blocks">
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <span style={{ color: 'rgb(50,50,50)' }}>Manage Scheduling Blocks</span>
        </MenuItem>
      </Menu>
    </>
  );
};

function itemName(item: UserScheduleConfigItem, idx: number) {
  return item.displayName || `Schedule Zone ${idx}`;
}
