import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Analytics from '../../analytics.jsx';
import { Notification, IconMenu } from '../../components';
import * as listOps from '../../operations/list';
import { Button, IconButton, MenuItem, Divider, Switch, Tooltip, Box, Stack } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ActionGrade from '@mui/icons-material/Grade';
import ShareIcon from '@mui/icons-material/Share';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { SortableList } from '../../components/SortableList';
import ListInvite from './ListInvite';
import ShareWithPeopleDialog from './ShareWithPeopleDialog';
import EditListForm from './EditListForm';
import { getPendingInvitations } from 'reducers/tasks';
import { acceptInvitation, declineInvitation } from '../../api';
import { styled } from '@mui/system';
import { DesktopOnlyBottomNotch } from '../../components/DesktopOnlyBottomNotch';
import { ListNameWithIcon } from 'components';
import { EnabledCalendarsDropdown } from 'modules/settings/EnabledCalendarsDropdown';
import { PremiumModalDock } from 'premium';
import { LockIcon } from 'components/LockIcon';
import { UserScheduleZonesDropdown } from 'modules/settings/UserScheduleZonesDropdown';
import { Edit, DeleteOutline, Add, InsertLink } from '@mui/icons-material';

export const FREE_LISTS_LIMIT = 5;

export const color = (c) => <span style={{ backgroundColor: c }} className="list-color-dot img-circle"></span>;

export const StyledList = styled(Box)(({ theme }) => ({
  margin: theme.mainBox.padding,
  padding: '6px 12px',
  backgroundColor: theme.palette.whiteBackground,
  boxShadow: theme.shadow.task,
  borderRadius: theme.mainBox.radiusInt / 3 + 'px',
  ['&:hover']: {
    boxShadow: theme.shadow.taskHover,
    transition: 'box-shadow 0.1s linear',
  },
}));

class ListsSettings extends React.Component {
  constructor() {
    super();
    this.state = {
      addingNewProject: false,
      editProjectId: null,
      shareProjectId: null,
    };
  }
  changeListEnabled(list, e) {
    if (list.default) {
      Notification.show('Default list cannot be disabled');
      return;
    }
    let enabled = e.target.checked;
    listOps.changeListEnabled(list, enabled);
  }
  addNewProject(project) {
    listOps.addNewList(project);
    this.setState({ addingNewProject: false });
    Analytics.event({
      category: 'List',
      action: 'Created List',
    });
  }
  removeList(list) {
    if (list.default) {
      Notification.show('Default list cannot be removed');
      return;
    }
    listOps.removeList(list);
    Analytics.event({
      category: 'List',
      action: 'Removed List',
    });
  }
  saveList(list, data) {
    listOps.editList({ ...list, ...data });
    this.setState({ editProjectId: null });
    Analytics.event({
      category: 'List',
      action: 'Renamed List',
    });
  }
  saveListAssociatedCalendarId(list, associatedCalendarId) {
    listOps.editList({ ...list, associatedCalendarId });
    Analytics.event({
      category: 'List',
      action: 'Set List Associated Calendar',
    });
  }
  saveListAssociatedUserScheduleZoneId(list, associatedUserScheduleZoneId) {
    listOps.editList({ ...list, associatedUserScheduleZoneId });
    Analytics.event({
      category: 'List',
      action: 'Set List Associated User Schedule Zone',
    });
  }
  reorderLists(lists, newOrder) {
    listOps.reorderLists(lists, newOrder);
    Analytics.event({
      category: 'List',
      action: 'Reordered lists',
    });
  }
  cancelListEdit() {
    this.setState({ editProjectId: null });
    Analytics.event({
      category: 'List',
      action: 'Canceled List Rename',
    });
  }
  cancelListCreate() {
    this.setState({ addingNewProject: false });
    Analytics.event({
      category: 'List',
      action: 'Canceled Create List',
    });
  }
  setDefaultList(list) {
    listOps.setDefaultList(list);
    Analytics.event({
      category: 'List',
      action: 'Set Default List',
    });
  }

  render() {
    const { goBack, pendingInvitations, onAcceptInvitation, onDeclineInvitation, lists, isPremium } = this.props;
    const { shareProjectId } = this.state;

    const handleAddNewProject = (e) => {
      e.preventDefault();
      this.setState({ addingNewProject: true });
    };

    let projectList = lists.map((list, index) => {
      if (list.id === this.state.editProjectId) {
        return (
          <StyledList elevation={0} key={list.id} className="list">
            <EditListForm
              list={list}
              onSave={(data) => this.saveList(list, data)}
              onCancel={() => this.cancelListEdit()}
            />
          </StyledList>
        );
      }

      let editOpts = '';
      if (list.origin === 'trevor') {
        editOpts = (
          <>
            <IconButton onClick={() => this.setState({ editProjectId: list.id })}>
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                if (window.confirm('Are you sure you want to permanently delete this List?')) {
                  this.removeList(list);
                }
              }}
            >
              <DeleteOutline sx={{ color: 'error.main' }} />
            </IconButton>
          </>
          // <IconMenu
          //   iconButtonElement={
          //     <IconButton>
          //       <MoreVertIcon />
          //     </IconButton>
          //   }
          //   anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          // >
          //   <MenuItem
          //     onClick={() => {
          //       this.setState({ shareProjectId: list.id });
          //     }}
          //   >
          //     Share
          //   </MenuItem>
          //   <MenuItem
          //     onClick={() => {
          //       this.setState({ editProjectId: list.id });
          //     }}
          //   >
          //     Edit
          //   </MenuItem>
          //   <MenuItem
          //     onClick={() => {
          //       if (window.confirm('Are you sure you want to permanently delete this List?')) {
          //         this.removeList(list);
          //       }
          //     }}
          //   >
          //     Remove
          //   </MenuItem>
          // </IconMenu>
        );
      } else if (list.origin === 'todoist') {
        editOpts = (
          <IconButton
            sx={{
              opacity: 0.7,
            }}
            href={`https://todoist.com/app?#project%2F${list.id.replace('@TrevorOrig:2', '')}`}
            target="_blank"
          >
            <OpenInNew />
          </IconButton>
        );
      }
      let defaultListIndication, setDefaultIcon;
      if (list.default) {
        defaultListIndication = (
          <Tooltip title="This is your default list. New tasks are added here by default." placement="bottom-start">
            <IconButton>
              <ActionGrade color="gold" />
            </IconButton>
          </Tooltip>
        );
      } else {
        setDefaultIcon = (
          <Tooltip title="Set list as default. New tasks will be added here by default." placement="bottom-start">
            <IconButton
              onClick={() => {
                this.setDefaultList(list);
              }}
            >
              <ActionGrade color="info" />
            </IconButton>
          </Tooltip>
        );
      }
      // let title = '#' + list.name;

      let sharedWith;

      if (list.shared && list.shared.members) {
        sharedWith = list.shared.members.map((u) => <MenuItem key={u.email}>{u.displayName || u.email}</MenuItem>);
      }

      let shareOps = (
        <IconMenu
          iconButtonElement={
            <Tooltip title="Share list" placement="bottom-start">
              <IconButton>
                <ShareIcon sx={({ palette }) => ({ color: list.shared ? palette.primary.dark : palette.info.light })} />
              </IconButton>
            </Tooltip>
          }
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          {sharedWith}
          {list.shared && <Divider />}
          {/* <MenuItem value="new" primaryText="Sharing Settings" onClick={()=>{this.setState({shareProjectId: list.id})}} /> */}
          {/* temporary disabled */}
          <MenuItem
            value="new"
            onClick={() => {
              this.setState({ shareProjectId: list.id });
            }}
          >
            Sharing Settings
          </MenuItem>
        </IconMenu>
      );

      return (
        <StyledList elevation={1} key={list.id} className="list drag-handle">
          <h1
            style={{
              color: list.color,
              fontSize: '1.3em',
              fontWeight: 300,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <ListNameWithIcon list={list} />
          </h1>
          <Stack
            // spacing={2}
            direction="row"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'center',
              gap: '2px 16px',
            }}
          >
            <Tooltip title="Enable / Disable list" placement="bottom-start">
              <Switch checked={list.enabled} onChange={this.changeListEnabled.bind(this, list)} />
            </Tooltip>
            {defaultListIndication}
            {setDefaultIcon}
            {/* {color(list.color)} */}
            {list.origin === 'trevor' && shareOps}
            {editOpts}
            <div style={{ flexGrow: 1, width: '100%' }} />
            <div style={{ width: 'calc(50% - 8px)' }}>
              <EnabledCalendarsDropdown
                selectedItemId={list.associatedCalendarId}
                onSelectItem={(associatedCalendarId) => {
                  if (!isPremium && associatedCalendarId) {
                    PremiumModalDock.showUpgradeModule('bind-calendar');
                    return;
                  }
                  console.log('Save associatedCalendarId', associatedCalendarId);
                  this.saveListAssociatedCalendarId(list, associatedCalendarId);
                }}
                placeholder="Calendar"
                tooltip="Override Scheduling Calendar"
                rightAction={!isPremium && <LockIcon />}
              />
            </div>
            <div style={{ width: 'calc(50% - 8px)' }}>
              {/* It can be null if no user schedule zones */}
              <UserScheduleZonesDropdown
                selectedItemId={list.associatedUserScheduleZoneId}
                onSelectItem={(associatedUserScheduleZoneId) => {
                  if (!isPremium && associatedUserScheduleZoneId) {
                    PremiumModalDock.showUpgradeModule('bind-schedule-zone');
                    return;
                  }
                  console.log('Save associatedUserScheduleZoneId', associatedUserScheduleZoneId);
                  this.saveListAssociatedUserScheduleZoneId(list, associatedUserScheduleZoneId);
                }}
                placeholder="Scheduling block"
                tooltip="Link List to Scheduling Block"
                rightAction={!isPremium && <LockIcon />}
              />
            </div>
          </Stack>
        </StyledList>
      );
    });

    let addNewProjectElement;
    if (this.state.addingNewProject) {
      addNewProjectElement = (
        <StyledList style={{ paddingTop: '15px', marginLeft: 0, marginRight: 0, padding: '12px' }}>
          <EditListForm
            list={{ name: '', color: 'default' }}
            onSave={(data) => this.addNewProject({ ...data, origin: 'trevor' })}
            onCancel={() => this.cancelListCreate()}
          />
        </StyledList>
      );
    } else {
      addNewProjectElement = (
        <Button variant="contained" color="primary" onClick={handleAddNewProject} fullWidth startIcon={<Add />}>
          Create #list
        </Button>
      );
    }

    let projectInvites = pendingInvitations.map((invite) => {
      let { inviterDisplayName, inviterEmail, listId, listName, listColor, key } = invite;
      return (
        <ListInvite
          {...{
            inviterDisplayName,
            inviterEmail,
            listId,
            listName,
            listColor,
            invitationId: key,
            onAcceptInvitation,
            onDeclineInvitation,
          }}
          key={key}
        />
      );
    });
    return (
      <Box
        id="projects-view"
        className="tasks-panel-tab-content list-content"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100% - 48px)', // Toolbar height
        }}
      >
        <Box
          elevation={0}
          className="tasks-panel-tab-content-container"
          sx={{
            height: '100%',
            overflowY: 'auto',
            overflowScrolling: 'touch',
            overflowX: 'hidden', // prevents drag & drop scrollbar
            position: 'relative', // required to properly display dragged item
            ['&>p']: {
              textAlign: 'center',
              margin: '1em',
            },
          }}
        >
          {projectInvites}
          <p className="faded" style={{ lineHeight: '1.5em' }}>
            Manage your Lists of tasks.
            <br />
            Only enabled lists will be shown in the Task Hub.
          </p>
          <Stack spacing={1} sx={{ padding: '6px 6px 32px 6px' }}>
            {addNewProjectElement}
            <Button
              variant="text"
              component={Link}
              to="/app/settings#integrations"
              fullWidth
              startIcon={<InsertLink />}
            >
              Sync Tasks from Other Apps
            </Button>
          </Stack>
          <SortableList
            handleClass="drag-handle"
            onChange={(newOrder) => this.reorderLists(lists, newOrder)}
            items={projectList}
          />

          <Stack spacing={1} sx={{ padding: '6px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                padding: '2px 6px',
                gap: '4px',
              }}
            >
              <img
                src="/images/reorder-list-hint.svg"
                alt="Reorder a task or schedule it with drag and drop"
                style={{ display: 'block', margin: '0 auto' }}
              />
            </Box>
            <Button variant="text" onClick={() => goBack()} fullWidth>
              Back to Task Hub
            </Button>
          </Stack>
        </Box>
        {shareProjectId && (
          <ShareWithPeopleDialog listId={shareProjectId} onClose={() => this.setState({ shareProjectId: null })} />
        )}
        <DesktopOnlyBottomNotch />
      </Box>
    );
  }
}

export default connect(
  (state, ownProps) => {
    return {
      ...ownProps,
      pendingInvitations: getPendingInvitations(state),
      isPremium: state.account.user.isPremium,
    };
  },
  (dispatch) => {
    const onAcceptInvitation = async (invitationId) => {
      await acceptInvitation(invitationId);
    };
    const onDeclineInvitation = async (invitationId) => {
      await declineInvitation(invitationId);
    };
    return {
      onAcceptInvitation,
      onDeclineInvitation,
    };
  }
)(ListsSettings);
